import React from 'react';
import PropTypes from 'prop-types';
import PageRenderer from '../components/page-renderer';

export default function Page(props) {
  const { pageContext } = props;
  const { title, content } = pageContext.page;

  return <PageRenderer title={title} content={content} />;
}

Page.propTypes = {
  data: PropTypes.shape({
    wordpressPage: PropTypes.any,
  }),
};
